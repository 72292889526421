@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  margin-bottom: 2.8rem;

  :global(.ant-form-item-label) {
    padding-bottom: 0.3rem;

    :global(label) {
      .inputLabel();
      color: @palette-color-neutrals-grey1;
    }
  }

  :global(.ant-form-item-control) {
    /*
    :global(.ant-form-item-control-input), :global(.ant-form-item-explain) {
      order: 1;
    }
    */

    :global(.ant-form-item-extra) {
      .caption1();
      color: @palette-color-neutrals-grey1;
    }

    :global(.ant-form-item-explain) {
      line-height: 1.6rem;
      margin-top: 0.4rem;
    }
  }
}
