@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  padding: 0.8rem 0.4rem 0.8rem 1.6rem;
  border: 0.2rem solid @palette-color-border-normal;
  border-radius: 1.2rem;
  width: 9rem;
  min-width: 9rem;

  &:global(.ant-picker-focused) {
    border-color: @palette-color-primary-blue;
    border-right-width: 0.2rem !important;
    box-shadow: none;
  }

  &:hover {
    border-color: @palette-color-shades-navy-cloudy-blue;
    border-right-width: 0.2rem !important;
    box-shadow: none;
    cursor: pointer;

    &:global(.ant-picker-range) {
      :global(.ant-picker-active-bar) {
        background: @palette-color-shades-navy-cloudy-blue;
      }
    }
  }

  &:focus {
    border-color: @palette-color-primary-blue;
    border-right-width: 0.2rem !important;
    box-shadow: none;
  }

  :global(.ant-picker-input) {
    height: 2rem;
  }

  input {
    .inputValue();
    color: @palette-color-neutrals-dark;
    border-radius: 0;
    cursor: pointer;

    &::placeholder {
      color: @palette-color-neutrals-grey2;
      border-right-width: 0.2rem !important;
    }

    &:hover {
      border-color: @palette-color-shades-navy-cloudy-blue;
      border-right-width: 0.2rem !important;
      box-shadow: none;
    }

    &:focus {
      border-color: @palette-color-primary-blue;
      border-right-width: 0.2rem !important;
      box-shadow: none;
    }
  }

  :global(.ant-picker-suffix) {
    display: flex;
    align-items: center;
    height: 2rem;
    margin-left: 0;
    position: absolute;
    right: .9rem;

    span {
      .colorSvg(@palette-color-neutrals-grey1);
    }
  }

  &:global(.ant-picker-range) {
    padding-right: 6rem;

    :global(.ant-picker-input) {
      :global(input) {
        text-align: center;
      }
    }

    :global(.ant-picker-active-bar) {
      background: @palette-color-primary-blue;
      margin-left: 1.6rem;
      height: .3rem;
      border-radius: .2rem;
    }

    :global(.ant-picker-clear) {
      margin-top: .3rem;
      width: 2.4rem;
      height: 2.4rem;
      right: 3rem;
      opacity: 1;
  
      span {
        .colorSvg(@palette-color-neutrals-grey2) !important;
  
        &:hover {
          .colorSvg(@palette-color-primary-blue) !important;
        }
      }
    }
  }

  &.big {
    height: 5rem;
    padding: 1.5rem 0.8rem 1.5rem 1.6rem;

    :global(.ant-picker-input) {
      height: 5rem;
    }
  }
}

.dropdown {
  :global(.ant-picker-range-arrow) {
    display: none;
  }

  :global(.ant-picker-panel-container) {
    border: 0.14rem solid @palette-color-border-light;
    box-shadow: @palette-shadow-1;
    border-radius: 1.2rem;
    padding: 2.4rem;

    :global(.ant-picker-header) {
      border-bottom: 0;

      & > button {
        display: flex;
        align-items: center;
      }

      :global(.anticon) {
        .colorSvg(@palette-color-neutrals-grey2);

        &:hover {
          .colorSvg(@palette-color-neutrals-grey1);
        }
      }
    }

    :global(.ant-picker-panel) {
      border: 0;
      width: 100%;

      &:not(.ant-picker-date-panel) {
        :global(.ant-picker-cell-in-view) {
          &:global(.ant-picker-cell-in-range) {
            &:global(.ant-picker-cell-range-hover-start) {
              &:before {
                background: @palette-color-fill-lavender;
              }
            }
          }
        }
      }
    }

    :global(.ant-picker-content) {
      height: auto;
    }
  }

  :global(.ant-picker-header-view) {
    .inputValue();
    line-height: 4rem;
    color: @palette-color-neutrals-grey1;

    & button:hover {
      color: @palette-color-primary-blue;
    }

    :global(.ant-picker-decade-btn) {
      &:hover {
        color: @palette-color-primary-blue;
      }
    }
  }

  :global(.ant-picker-cell) {
    &:before {
      height: 3.8rem;
    }

    :global(.ant-picker-cell-inner) {
      .bodyBase2Medium();
      color: @palette-color-neutrals-grey2;
      height: 3.8rem;
      padding: 1rem;
      border: 0.1rem solid @palette-color-fill-sky;
      border-radius: @border-radius-base;
      background-color: @palette-color-neutrals-white;
      user-select: none;
    }

    &:hover {
      :global(.ant-picker-cell-inner) {
        background-color: @palette-color-neutrals-white !important;
        border-color: @palette-color-shades-navy-cloudy-blue;
        color: @palette-color-neutrals-grey2;
      }
    }

    &:global(.ant-picker-cell-in-view) {
      :global(.ant-picker-cell-inner) {
        .bodyBase2Medium();
        color: @palette-color-neutrals-grey1;
        height: 3.8rem;
        padding: 1rem;
        border: 0.1rem solid @palette-color-border-light;
        border-radius: @border-radius-base;
        background-color: @palette-color-neutrals-white;
        user-select: none;
      }
  
      &:hover {
        :global(.ant-picker-cell-inner) {
          background-color: @palette-color-neutrals-white !important;
          border-color: @palette-color-shades-navy-cloudy-blue;
          color: @palette-color-neutrals-grey1;
        }
      }

      &:global(.ant-picker-cell-in-range) {
        &:before {
          background: @palette-color-fill-lavender;
        }
      }
      
      &:global(.ant-picker-cell-range-end) {
        &:not(.ant-picker-cell-range-end-single) {
          &:before {
            background: @palette-color-fill-lavender;
          }
        }
      }

      &:global(.ant-picker-cell-range-start) {
        &:not(.ant-picker-cell-range-start-single) {
          &:before {
            background: @palette-color-fill-lavender;
          }
        }
      }
    }

    &:global(.ant-picker-cell-disabled) {
      pointer-events: auto;

      :global(.ant-picker-cell-inner) {
        cursor: not-allowed;

        &:hover {
          background-color: transparent !important;
        }
      }
    }
  }

  :global(.ant-picker-decade-panel) {
    :global(.ant-picker-cell-inner) {
      height: 5.6rem;
      width: 6rem;
    }
  }

  :global(.ant-picker-cell-selected) {
    &:global(.ant-picker-cell-in-view) {
      :global(.ant-picker-cell-inner) {
        .bodyBase2Bold();
        background-color: @palette-color-primary-blue;
        border-color: @palette-color-primary-blue;
        color: @palette-color-neutrals-white;
      }
    }
  }

  :global(.ant-picker-year-panel),
  :global(.ant-picker-month-panel),
  :global(.ant-picker-decade-panel) {
    width: 22rem;
  }

  :global(.ant-picker-date-panel) {
    width: 30rem;

    :global(.ant-picker-content) {
      width: 100%;

      :global(.ant-picker-cell) {
        padding: 3.9px 0;

        &:global(.ant-picker-cell-in-range) {
          &:global(.ant-picker-cell-range-hover-start) {
            :global(.ant-picker-cell-inner) {
              &:after {
                display: none;
              }
            }
          }

          &:global(.ant-picker-cell-range-hover-end) {
            :global(.ant-picker-cell-inner) {
              &:after {
                display: none;
              }
            }
          }

          &:before {
            background: @palette-color-fill-lavender;
          }
        }

        &:global(.ant-picker-cell-range-start) {
          &:not(.ant-picker-cell-range-start-single) {
            &:before {
              background: @palette-color-fill-lavender;
              border-radius: 1rem 0 0 1rem;
            }
          }

          :global(.ant-picker-cell-inner) {
            .bodyBase2Bold();
            border-color: @palette-color-primary-blue;
            border-radius: 1rem 0 0 1rem;
          }
        }

        &:global(.ant-picker-cell-range-end) {
          &:not(.ant-picker-cell-range-end-single) {
            &:before {
              background: @palette-color-fill-lavender;
              border-radius: 0 1rem 1rem 0;
            }
          }

          :global(.ant-picker-cell-inner) {
            .bodyBase2Bold();
            border-color: @palette-color-primary-blue;
            border-radius: 0 1rem 1rem 0;
          }
        }
      }

      :global(.ant-picker-cell-inner) {
        padding: 1rem 0;
        width: calc(100% - .5rem);
      }

      :global(.ant-picker-cell-in-view) {
        &:global(.ant-picker-cell-today) {
          :global(.ant-picker-cell-inner::before) {
            border: none;
          }
        }
      }
    }

    :global(.ant-picker-header-view) {
      button {
        &:hover {
          color: @palette-color-primary-blue;
        }
      }
    }
  }

  :global(.ant-picker-datetime-panel) {
    height: 35.1rem;
    overflow: hidden;
    margin-bottom: .4rem;
  }

  :global(.ant-picker-time-panel) {
    border-left: .1rem solid @palette-color-border-light;
    padding-left: 1rem;

    :global(.ant-picker-header) {
      margin-bottom: 1.1rem;
    }

    :global(.ant-picker-content) {
      height: 31.8rem;

      :global(.ant-picker-time-panel-column) {
        width: 5rem;
        margin-left: .2rem;

        &:not(:first-child) {
          border-left: none;
        }
      }

      :global(.ant-picker-time-panel-cell-inner) {
        .bodyBase2Medium();
        line-height: 3rem;
        height: 3rem;
        color: @palette-color-neutrals-grey1;
        border-radius: 1rem;

        &:hover {
          background-color: @palette-color-fill-grey;
        }
      }

      :global(.ant-picker-time-panel-cell-selected) {
        :global(.ant-picker-time-panel-cell-inner) {
          .bodyBase2Bold();
          line-height: 3rem;
          height: 3rem;
          background-color: @palette-color-primary-blue;
          color: @palette-color-neutrals-white;
        }
      }
    }
  }

  :global(.ant-picker-today-btn) {
    color: @palette-color-primary-blue;
  }

  :global(.ant-picker-footer) {
    border-top: none;
  }

  :global(.ant-picker-ranges) {
    padding: 1.6rem 1.2rem .6rem;
    text-align: center;
    line-height: normal;

    :global(.ant-picker-ok) {
      margin-left: 0;
      float: none;

      button {
        .bodyBase1Medium();
        color: @palette-color-primary-blue;
        background-color: transparent;
        border: none;
        box-shadow: none;
        text-shadow: none;

        &:disabled {
          color: @palette-color-neutrals-grey2;
        }
      }
    }
  }
}
