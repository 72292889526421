@import '~@palette/styles/typographies.less';
@import '~@palette/styles/colors.less';

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .results {
    .bodyBase2Medium();
    color: @palette-color-neutrals-dark;
  }

  .pagesControls {
    margin-left: 0.8rem;

    display: flex;
    flex-direction: row;
    align-items: center;

    .prevNextBtn {
      width: 2.4rem;
      height: 2.4rem;
    }

    .pages {
      display: flex;
      flex-direction: row;
      align-items: center;

      .page {
        margin: 0 0.5rem;
      }

      .currentPage {
        .bodyBase2Bold();
        color: @palette-color-primary-blue;
        text-decoration: underline;
      }

      .otherPages {
        .bodyBase2Medium();
        color: @palette-color-neutrals-grey2;
      }

      .pageBtn {
        .bodyBase2Medium();
        color: @palette-color-neutrals-grey2;

        &:hover {
          .bodyBase2Medium();
          color: @palette-color-primary-blue;
        }
      }
    }
  }

  .limitSelector {
    margin-left: 1.2rem;
  }

  .directPageSelectorLabel {
    margin-left: 1.6rem;

    .bodyBase2Medium();
    color: @palette-color-neutrals-dark;
  }

  .directPageSelectorInput {
    margin-left: 1.6rem;

    max-width: 8rem;
  }
}
