@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  :global(.ant-popover-arrow) {
    display: none;
  }

  :global(.ant-popover-inner) {
    background-color: @palette-color-neutrals-dark;

    border: 0.1rem solid @palette-color-neutrals-grey1;
    box-sizing: border-box;
    box-shadow: none;
    border-radius: 0.4rem;

    :global(.ant-popover-inner-content) {
      padding: 0.6rem 1rem;

      :global(.ant-popconfirm-inner-content) {
        :global(.ant-popconfirm-message) {
          padding: 0;

          :global(.ant-popconfirm-title) {
            padding: 0;
            .bodyBase2Medium();
            color: @palette-color-neutrals-white;
          }
        }

        :global(.ant-popconfirm-buttons) {
          margin-top: 1rem;
          margin-bottom: 0.6rem;
          display: flex;
          align-items: center;
          justify-content: flex-end;

          :global(.ant-btn) {
            display: flex;
            align-items: center;
            height: 4rem;
            border-radius: @border-radius-base;
            border: none;
            box-shadow: none;
            text-transform: none;


            background-color: transparent;
            .bodyBase1Bold();
            color: @palette-color-neutrals-grey1;
            padding: 0.9rem 1.6rem;

            & > :global(.anticon + span) {
              margin-left: 0.4rem;
            }

            :global(.anticon) {
              display: flex;
              .colorSvg(@palette-color-neutrals-grey1);
            }

            &:hover {
              background-color: @palette-color-fill-sky;
              .bodyBase1Bold();
              color: @palette-color-neutrals-grey1;
              box-shadow: none;

              :global(.anticon) {
                .colorSvg(@palette-color-neutrals-grey1);
              }
            }

            &:active {
              background-color: @palette-color-neutrals-dark;
              .bodyBase1Bold();
              color: @palette-color-neutrals-white;
              box-shadow: none;

              :global(.anticon) {
                .colorSvg(@palette-color-neutrals-white);
              }
            }

            &:focus, &.pressed {
              background-color: @palette-color-neutrals-dark;
              .bodyBase1Bold();
              color: @palette-color-neutrals-white;
              box-shadow: none;

              :global(.anticon) {
                .colorSvg(@palette-color-neutrals-white);
              }
            }

            &.disabled {
              cursor: not-allowed;


              background-color: transparent;
              .bodyBase1Bold();
              color: @palette-color-neutrals-grey2;


              :global(.anticon) {
                .colorSvg(@palette-color-neutrals-grey2);
              }

              &:hover, &:active, &:focus {
                background-color: transparent;
                .bodyBase1Bold();
                color: @palette-color-neutrals-grey2;

                :global(.anticon) {
                  .colorSvg(@palette-color-neutrals-grey2);
                }
              }
            }

            &:global(.ant-btn-primary) {
              background-color: @palette-color-primary-teal;
              .bodyBase1Bold();
              color: @palette-color-neutrals-white;

              :global(.anticon) {
                .colorSvg(@palette-color-neutrals-white);
              }

              &:hover, &:active {
                background-color: @palette-color-shades-teal-hover;
                .bodyBase1Bold();
                color: @palette-color-neutrals-white;

                :global(.anticon) {
                  .colorSvg(@palette-color-neutrals-white);
                }
              }

              &:focus, &.pressed {
                background-color: @palette-color-primary-teal;
                .bodyBase1Bold();
                color: @palette-color-neutrals-white;

                :global(.anticon) {
                  .colorSvg(@palette-color-neutrals-white);
                }
              }

              &.disabled {
                background-color: @palette-color-shades-primary-teal-ghost;
                .bodyBase1Bold();
                color: @palette-color-neutrals-white;

                :global(.anticon) {
                  .colorSvg(@palette-color-neutrals-white);
                }

                &:hover, &:active, &:focus {
                  background-color: @palette-color-shades-primary-teal-ghost;
                  .bodyBase1Bold();
                  color: @palette-color-neutrals-white;

                  :global(.anticon) {
                    .colorSvg(@palette-color-neutrals-white);
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.small {
    :global(.ant-popover-inner) {
      max-width: 16rem;
    }
  }
}
