@import '~@palette/styles/typographies.less';
@import '~@palette/styles/colors.less';

@keyframes linearGradientMove {
  100% {
    background-position: 0.4rem 0, -0.4rem 100%, 0 -0.4rem, 100% 0.4rem;
  }
}

.wrapper {
  position: relative;

  border-radius: 1.8rem;
  border: 0.1rem solid @palette-color-border-normal;
  padding: 1.5rem 2rem;
  min-width: fit-content;
  display: flex;
  flex-flow: column nowrap;

  &.autoSyncIsPending {
    border: none;
    border-radius: 0;
    background:
      linear-gradient(90deg, @palette-color-secondary-brown-yellow 50%, transparent 0) repeat-x,
      linear-gradient(90deg, @palette-color-secondary-brown-yellow 50%, transparent 0) repeat-x,
      linear-gradient(0deg, @palette-color-secondary-brown-yellow 50%, transparent 0) repeat-y,
      linear-gradient(0deg, @palette-color-secondary-brown-yellow 50%, transparent 0) repeat-y;
    background-size: 0.4rem 0.1rem, 0.4rem 0.1rem, 0.1rem 0.4rem, 0.1rem 0.4rem;
    background-position: 0 0, 0 100%, 0 0, 100% 0;

    animation: linearGradientMove 0.3s infinite linear;
  }

  .infosWrapper {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;

    & + .infosWrapper {
      margin-top: 1rem;
    }

    .infosBlock {
      display: flex;
      align-items: center;

      .infosBlockItem {
        & + .infosBlockItem {
          margin-left: 1rem;
        }

        &.autoBtnSyncIsPending {
          color: @palette-color-secondary-brown-yellow;

          :global(.anticon) {
            .colorSvg(@palette-color-secondary-brown-yellow);
          }
        }

        &.tooOldToAutoSync {
          display: flex;
          align-items: center;

          .tooOldToAutoSyncIcon {
            .colorSvg(@palette-color-secondary-red);
          }

          .tooOldToAutoSyncDescription {
            margin-left: 0.6rem;

            .bodyBase2Medium();
            color: @palette-color-secondary-red;
          }
        }
      }
    }
  }

  .launchDateWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;

    .launchDate {
      margin-left: 0.6rem;

      .caption1Italic();
      color: @palette-color-neutrals-grey2;
    }
  }

  .lastActivityAtWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;

    .lastActivityAtIcon {
      .colorSvg(@palette-color-secondary-brown-yellow);
    }

    .lastActivityAt {
      margin-left: 0.6rem;

      .caption1();
      color: @palette-color-secondary-brown-yellow;
    }
  }

  .durationWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;

    .durationIcon {
      .colorSvg(@palette-color-neutrals-dark);
    }

    .duration {
      margin-left: 0.6rem;

      .bodyBase2Medium();
      color: @palette-color-neutrals-dark;
    }
  }

  .userJobWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;

    .botIcon {
      .colorSvg(@palette-color-primary-blue);
    }

    .touchIcon {
      .colorSvg(@palette-color-primary-teal);
    }

    .userJobDescription {
      margin-left: 0.6rem;

      .bodyBase2Medium();
      color: @palette-color-neutrals-dark;
    }
  }

  .writeLockWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;

    .writeLockIcon {
      .colorSvg(@palette-color-neutrals-dark);
    }

    .writeLockDescription {
      margin-left: 0.6rem;

      .bodyBase2Medium();
      color: @palette-color-neutrals-dark;
    }

    &.isSaving {
      .writeLockIcon {
        .colorSvg(@palette-color-primary-teal);
      }

      .writeLockDescription {
        color: @palette-color-primary-teal;
      }
    }
  }
}
