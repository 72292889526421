@import '~@palette/styles/typographies.less';
@import '~@palette/styles/colors.less';

.modal {
  :global(.ant-modal) {
    min-width: 65rem;
    max-width: 65rem;
  }

  .logoFormItem {
    :global(.ant-form-item-control-input-content) {
      display: flex;
      justify-content: center;
    }
  }

  .nameFieldWrapper {
    margin-bottom: 1rem;

    .nameFormItem {
      margin-bottom: 0.2rem;
    }

    .similarCompaniesWrapper {
      min-height: 1.6rem;
      display: flex;
      align-items: center;

      .caption1();
      color: @palette-color-neutrals-grey1;

      .similarCompaniesLoaderIcon {
        .colorSvg(@palette-color-neutrals-grey1);
      }
    }
  }
}
