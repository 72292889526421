@import '~@palette/styles/fonts.less';
@import '~@palette/styles/palette-theme.less';

/* Headlines */
.headlineH1() {
  font-family: @font-family;
  font-style: normal;
  font-weight: @palette-font-weight-black;
  font-size: 3.6rem;
  line-height: 4rem;
}

.headlineH2-2() {
  font-family: @font-family;
  font-style: normal;
  font-weight: @palette-font-weight-black;
  font-size: 3.2rem;
  line-height: 4rem;
}

.headlineH2() {
  font-family: @font-family;
  font-style: normal;
  font-weight: @palette-font-weight-black;
  font-size: 2.4rem;
  line-height: 3.2rem;
}

.headlineH3() {
  font-family: @font-family;
  font-style: normal;
  font-weight: @palette-font-weight-black;
  font-size: 2rem;
  line-height: 2.6rem;
}

/* Titles */
.title1Bold() {
  font-family: @font-family;
  font-style: normal;
  font-weight: @palette-font-weight-bold;
  font-size: 2rem;
  line-height: 2.4rem;
}

.title1Medium() {
  font-family: @font-family;
  font-style: normal;
  font-weight: @palette-font-weight-medium;
  font-size: 2rem;
  line-height: 2rem;
}

.title2Bold() {
  font-family: @font-family;
  font-style: normal;
  font-weight: @palette-font-weight-bold;
  font-size: 1.8rem;
  line-height: 2.4rem;
}

.title2Medium() {
  font-family: @font-family;
  font-style: normal;
  font-weight: @palette-font-weight-medium;
  font-size: 1.8rem;
  line-height: 2.4rem;
}

/* Body */
.bodyBase1Medium() {
  font-family: @font-family;
  font-style: normal;
  font-weight: @palette-font-weight-medium;
  font-size: 1.5rem;
  line-height: 2rem;
}

.bodyBase1Bold() {
  font-family: @font-family;
  font-style: normal;
  font-weight: @palette-font-weight-bold;
  font-size: 1.5rem;
  line-height: 2rem;
}

.bodyBase2Medium() {
  font-family: @font-family;
  font-style: normal;
  font-weight: @palette-font-weight-medium;
  font-size: 1.4rem;
  line-height: 1.8rem;
}

.bodyBase2MediumItalic() {
  font-family: @font-family;
  font-style: italic;
  font-weight: @palette-font-weight-medium;
  font-size: 1.4rem;
  line-height: 1.8rem;
}

.bodyBase2Bold() {
  font-family: @font-family;
  font-style: normal;
  font-weight: @palette-font-weight-bold;
  font-size: 1.4rem;
  line-height: 1.8rem;
}

.bodyBase2BoldItalic() {
  font-family: @font-family;
  font-style: italic;
  font-weight: @palette-font-weight-bold;
  font-size: 1.4rem;
  line-height: 1.8rem;
}

/* Captions */
.caption1() {
  font-family: @font-family;
  font-style: normal;
  font-weight: @palette-font-weight-medium;
  font-size: 1.2rem;
  line-height: 1.6rem;
}

.caption1Italic() {
  font-family: @font-family;
  font-style: italic;
  font-weight: @palette-font-weight-medium;
  font-size: 1.2rem;
  line-height: 1.6rem;
}

.caption1Bold() {
  font-family: @font-family;
  font-style: normal;
  font-weight: @palette-font-weight-bold;
  font-size: 1.2rem;
  line-height: 1.6rem;
}

/* Inputs */
.inputLabel() {
  font-family: @font-family;
  font-style: normal;
  font-weight: @palette-font-weight-medium;
  font-size: 1.5rem;
  line-height: 2rem;
}

.inputValue() {
  font-family: @font-family;
  font-style: normal;
  font-weight: @palette-font-weight-medium;
  font-size: 1.6rem;
  line-height: 2rem;
}


/* Codes */
.code() {
  font-family: 'SpaceMono', 'monospace';
  font-style: normal;
  font-weight: @palette-font-weight-regular;
  font-size: 1.2rem;
  line-height: 1.6rem;
}
