@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  border: 0;

  :global(.ant-menu-item) {
    padding: 1.6rem;

    display: flex;
    flex-direction: row;
    align-items: center;

    :global(.ant-menu-item-icon) {
      .colorSvg(@palette-color-neutrals-dark);
    }

    :global(.ant-menu-title-content) {
      .bodyBase2Medium();
      color: @palette-color-neutrals-dark;
    }

    &:global(.ant-menu-item-selected) {
      :global(.ant-menu-item-icon) {
        .colorSvg(@palette-color-primary-blue);
      }

      :global(.ant-menu-title-content) {
        .bodyBase2Bold();
        color: @palette-color-primary-blue;
      }

      &::after {
        border-bottom-color: @palette-color-primary-blue;
      }
    }

    &:global(.ant-menu-item-active) {
      :global(.ant-menu-item-icon) {
        .colorSvg(@palette-color-primary-blue);
      }

      :global(.ant-menu-title-content) {
        color: @palette-color-primary-blue;
      }

      &::after {
        border-bottom-color: @palette-color-primary-blue;
      }
    }
  }
}
