@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  .bodyBase2Medium();
  color: @palette-color-neutrals-grey1;
  padding: 1.3rem 1.4rem;

  &.borderless {
    padding: 2.1rem 1.4rem;
  }
}
