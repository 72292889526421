@import '~@palette/styles/typographies';
@import '~@palette/styles/colors';

.wrapper {
  .statsWrapper {
    .statsTitle {
      .bodyBase1Bold();
      color: @palette-color-neutrals-grey1;
    }
  }

  .tabs {
    margin-top: 2.4rem;
  }
}
