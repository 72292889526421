@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  height: 100%;

  > :global(.ant-tabs-nav) {
    &::before {
      border-color: @palette-color-border-normal;
    }
  }

  :global(.ant-tabs-tab) {

    :global(.ant-tabs-tab-btn) {
      .bodyBase1Medium();
      color: @palette-color-neutrals-dark;

      .titleWithCount {
        display: flex;
        flex-direction: row;
        align-items: center;

        .bodyBase1Medium();
        color: @palette-color-neutrals-dark;

        .count {
          white-space: break-spaces;

          .bodyBase1Medium();
          color: @palette-color-neutrals-grey2;
        }
      }
    }

    &:global(.ant-tabs-tab-active) :global(.ant-tabs-tab-btn) {
      color: @palette-color-primary-blue;

      .titleWithCount {
        color: @palette-color-primary-blue;

        .count {
          color: @palette-color-primary-blue;
        }
      }
    }
  }

  > :global(.ant-tabs-nav) :global(.ant-tabs-ink-bar) {
    height: 0.4rem;
    background-color: @palette-color-primary-blue;
    border-radius: 0.5rem;
  }

  :global(.ant-tabs-content-holder) {
    flex: 1;

    :global(.ant-tabs-content) {
      height: 100%;
    }
  }

  &.stretched {
    > :global(.ant-tabs-nav) {
      :global(.ant-tabs-nav-wrap) {
        :global(.ant-tabs-nav-list) {
          width: 100%;
          justify-content: space-between;
        }
      }
    }

    :global(.ant-tabs-tab) {
      + :global(.ant-tabs-tab) {
        margin: 0;
      }
    }
  }

  &.disableSeeMoreTabsBtn {
    > :global(.ant-tabs-nav) {
      :global(.ant-tabs-nav-wrap) {
        overflow: auto;
      }

      :global(.ant-tabs-nav-operations) {
        display: none;
      }
    }
  }
}
