@import '~@palette/styles/colors.less';
@import '~@palette/styles/mixins.less';

.wrapper {
  .center(~':global(svg)');

  &.preFillInWhite {
    .colorSvg(@palette-color-neutrals-white);
  }

  &.spin {
    .spin();
  }
}
