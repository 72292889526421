.wrapper {
  width: 100%;

  .passwordRules {
    margin-bottom: 2.8rem;
  }

  .submitBtn {
    width: 100%;
    justify-content: center;
  }
}
