@import '~@palette/styles/typographies.less';
@import '~@palette/styles/colors.less';

.wrapper {
  .job {
    margin-top: 2rem;
  }

  .pagination {
    margin-top: 4.4rem;
  }
}
