@import '~@palette/styles/typographies';
@import '~@palette/styles/colors';

.wrapper {
  width: 100%;

  .displayModeWrapper {
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: space-between;

    .noTrialSet {
      .inputLabel();
      color: @palette-color-neutrals-grey1;
    }

    .documentationUrlWrapper {
      width: 100%;
      display: flex;
      align-items: center;

      .documentationUrlIcon {
        margin-right: 1rem;
        .colorSvg(@palette-color-primary-blue);
      }

      .noDocumentationUrlSet {
        .caption1Italic();
        color: @palette-color-neutrals-grey1;
      }

      .companyDocumentationLink {
        //
      }
    }
  }

  .editModeWrapper {
    .trialEndDateDatePicker {
      width: 100%;
    }

    .actions {
      display: flex;
      flex-direction: column;
      align-items: center;

      .updateDocumentationUrlBtn {
        width: 100%;
        justify-content: center;
      }
    }
  }
}
