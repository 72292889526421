@import '~@palette/styles/palette-theme.less';

.wrapper {
  margin: 0 auto;
  max-width: 100%;

  .header {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 5rem;

    border-bottom: 0.1rem solid @palette-color-border-light;

    .menu {
      margin-left: 2rem;

      width: 40rem;
    }

    .headerItem {
      margin-left: 2rem;
    }

    .logoutBtn {
      margin-left: 5rem;
    }
  }

  .content {
    padding: 2rem;
  }

  @media(min-width: @screen-xl) {
    max-width: 110rem;
  }

  @media(min-width: @screen-xxl) {
    max-width: 140rem;
  }
}
