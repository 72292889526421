@import '~@palette/styles/typographies';
@import '~@palette/styles/colors';

.connectorsTooltip {
  max-width: 30rem;

  .connectorsTypesList {
    display: flex;
    flex-flow: row wrap;

    .connectorType {
      margin: 0.5rem;
    }
  }
}

.jobsStatsTooltip {
  max-width: 30rem;
}

.mongoCollectionsStatsTooltip {
  max-width: 30rem;
}

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;

  .connectorsWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;

    .connectorsIcon {
      .colorSvg(@palette-color-neutrals-dark);
    }

    .connectorsCount {
      margin-left: 0.6rem;

      .bodyBase1Medium();
      color: @palette-color-neutrals-dark;
    }
  }

  .plansWrapper {
    margin-left: 1rem;

    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;

    .plansIcon {
      .colorSvg(@palette-color-neutrals-dark);
    }

    .plans {
      margin-left: 0.6rem;

      .bodyBase1Medium();
      color: @palette-color-neutrals-dark;
    }
  }

  .quotasWrapper {
    margin-left: 1rem;

    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;

    .quotasIcon {
      .colorSvg(@palette-color-neutrals-dark);
    }

    .quotas {
      margin-left: 0.6rem;

      .bodyBase1Medium();
      color: @palette-color-neutrals-dark;
    }
  }

  .challengesWrapper {
    margin-left: 1rem;

    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;

    .challengesIcon {
      .colorSvg(@palette-color-neutrals-dark);
    }

    .challenges {
      margin-left: 0.6rem;

      .bodyBase1Medium();
      color: @palette-color-neutrals-dark;
    }
  }

  .reconciliationsWrapper {
    margin-left: 1rem;

    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;

    .reconciliationsIcon {
      .colorSvg(@palette-color-neutrals-dark);
    }

    .reconciliations {
      margin-left: 0.6rem;

      .bodyBase1Medium();
      color: @palette-color-neutrals-dark;
    }
  }

  .jobsStatsWrapper {
    border-left: 0.1rem solid @palette-color-border-light;

    margin-left: 1rem;
    padding-left: 1rem;

    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;

    .jobsStatsIcon {
      .colorSvg(@palette-color-neutrals-dark);
    }

    .jobsStatsAverageDuration {
      margin-left: 0.6rem;

      .bodyBase1Medium();
      color: @palette-color-neutrals-dark;
    }
  }

  .mongoCollectionsStatsWrapper {
    margin-left: 1rem;

    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;

    .mongoCollectionsStatsIcon {
      .colorSvg(@palette-color-neutrals-dark);
    }

    .mongoCollectionsStatsTotal {
      margin-left: 0.6rem;

      .bodyBase1Medium();
      color: @palette-color-neutrals-dark;
    }
  }
}
