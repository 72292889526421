@import '~@palette/styles/typographies';
@import '~@palette/styles/colors';

.wrapper {
  min-width: 8rem;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  .stripeIcon {
    cursor: pointer;
    width: 2rem;
    height: 2rem;

    margin-right: 1rem;
  }

  .archivedIcon {
    cursor: pointer;

    .colorSvg(@palette-color-neutrals-grey2);

    &.isArchived {
      .colorSvg(@palette-color-secondary-brown-yellow);
    }
  }

  .liveIcon {
    margin-left: 1rem;
    cursor: pointer;

    .colorSvg(@palette-color-primary-teal);
  }

  .notLiveIcon {
    margin-left: 1rem;
    cursor: pointer;

    .colorSvg(@palette-color-shades-secondary-red-hover);
  }
}
