@import '~@palette/styles/colors.less';
@import '~@palette/styles/palette-theme.less';

.footer {
  width: fit-content;
  border-bottom-left-radius: @border-radius-base;
  border-bottom-right-radius: @border-radius-base;
  background-color: @palette-color-neutrals-white;

  &.stretch {
    width: 100%;
  }

  .footerRow {
    display: flex;
    flex-direction: row;

    .footerCell {
      background-color: @palette-color-neutrals-white;
      border: 0.1rem solid @palette-color-border-light;
      border-left-width: 0;
      display: flex;
      align-items: center;

      &:first-child {
        border-bottom-left-radius: @border-radius-base;
        border-left-width: 0.1rem;
        overflow: hidden;
      }

      &:last-child, &.lastNotHiddenColumn {
        border-bottom-right-radius: @border-radius-base;
        overflow: hidden;
      }

      &.highlight {
        background-color: @palette-color-fill-teal-fill;
      }

      &.hidden {
        border: none !important;
        width: 0 !important;
        overflow: visible;

        &:first-child + .footerCell {
          border-bottom-left-radius: @border-radius-base;
          border-left-width: 0.1rem;
          overflow: hidden;
        }
      }

      &.stickyCell {
        position: sticky;
        background-color: @palette-color-neutrals-white;
        z-index: 100;
      }

      &.withShadow {
        transition: box-shadow 0.3s;
        overflow: visible !important;

        &::after {
          content: "";
          pointer-events: none;
          position: absolute;
          top: 0;
          right: -1rem;
          bottom: 0;
          z-index: 1;
          width: 1rem;
          box-shadow: inset 1rem 0 0.8rem -0.8rem rgb(0 0 0 / 15%);
        }
      }

      &.withOppositeShadow {
        transition: box-shadow 0.3s;

        &::after {
          content: "";
          pointer-events: none;
          position: absolute;
          top: 0;
          left: -1rem;
          bottom: 0;
          z-index: 1;
          width: 1rem;
          box-shadow: inset -1rem 0 0.8rem -0.8rem rgb(0 0 0 / 15%);
        }
      }

      &.fixedColumnsFromRight {
        &.withShadow {
          &::after {
            left: -1rem;
            right: initial;
            box-shadow: inset -1rem 0 0.8rem -0.8rem rgb(0 0 0 / 15%);
          }
        }

        &.withOppositeShadow {
          &::after {
            right: -1rem;
            left: initial;
            box-shadow: inset 1rem 0 0.8rem -0.8rem rgb(0 0 0 / 15%);
          }
        }
      }
    }
  }

  &.borderless {
    .footerRow {
      .footerCell {
        border: none;
        border-radius: 0;
        border-bottom: 0.1rem solid @palette-color-border-light;

        &.hidden {
          border: none;
          border-radius: 0;

          + .footerCell {
            border: none;
            border-radius: 0;
            border-bottom: 0.1rem solid @palette-color-border-light;
          }
        }
      }
    }
  }
}
