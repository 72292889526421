@import '~@palette/styles/colors.less';

.wrapper {
  user-select: none;
  overflow: visible;

  border-radius: 1.8rem;
  border: 0.1rem solid @palette-color-border-light;
  padding: 1.5rem 2rem;
  min-width: fit-content;
  display: flex;
  flex-flow: column nowrap;

  .globalInfosWrapper {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
  }

  .detailsWrapper {
    margin-top: 0.6rem;

    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
  }
}
