@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  height: 100%;
  max-width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h1 {
    margin-top: 4rem;
  }

  .notFoundIllu {
    margin-top: 4rem;

    width: 50rem;
    height: auto;
  }

  .details {
    margin-top: 4rem;

    .bodyBase2Medium();
    color: @palette-color-neutrals-grey1;
  }
}
