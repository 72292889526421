@import '~@palette/styles/typographies.less';
@import '~@palette/styles/colors.less';

.wrapper {
  position: relative;

  border-radius: 1.8rem;
  border: 0.1rem solid @palette-color-border-normal;
  padding: 1.5rem 2rem;
  min-width: fit-content;
  display: flex;
  flex-flow: column nowrap;

  .header {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;

    .displayNameWrapper {
      display: flex;
      align-items: center;

      .displayName {
        margin-left: 0.5rem;

        .bodyBase1Bold();
        color: @palette-color-neutrals-dark;
      }
    }

    .roleStatusWrapper {
      display: flex;
      align-items: center;

      .statusTag {
        margin-left: 1rem;
        cursor: default;

        &.clickableTag {
          cursor: pointer;
        }

        .statusWrapper {
          display: flex;
          align-items: center;

          .statusLabel {
            margin-left: 0.5rem;
          }
        }
      }
    }
  }

  .footer {
    margin-top: 1rem;

    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;

    .emailWrapper {
      display: flex;
      align-items: center;

      .email {
        .bodyBase2MediumItalic();
        color: @palette-color-neutrals-grey1;
      }

      .copyEmailToClipboardBtn {
        margin-left: 1rem;

        :global(.anticon) {
          .colorSvg(@palette-color-neutrals-grey1);
        }

        &:hover {
          :global(.anticon) {
            .colorSvg(@palette-color-primary-blue);
          }
        }
      }
    }

    .actionsWrapper {
      margin-left: 1rem;
    }
  }
}
