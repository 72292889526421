@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  .bodyBase2Medium();
  color: @palette-color-neutrals-grey1;
  padding: 1.2rem 1.4rem;
  width: 100%;

  &.borderless {
    padding: 1rem 0.5rem;

    .childrenWrapper {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;


      @supports (-webkit-line-clamp: 2) {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: initial;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    }
  }
}
