@import '~@palette/styles/typographies';
@import '~@palette/styles/colors';

.wrapper {
  .header {
    margin-top: 2.4rem;

    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
      .title1Bold();
      color: @palette-color-neutrals-dark;
    }
  }

  .companyUsersList {
    margin-top: 2.4rem;
  }
}
