@import '~@palette/styles/typographies.less';
@import '~@palette/styles/colors.less';

.wrapper {
  position: relative;
  user-select: none;
  overflow: visible;

  text-decoration: none;

  border-radius: 1.8rem;
  border: 0.1rem solid @palette-color-border-normal;
  padding: 1.5rem 2rem;
  min-width: fit-content;
  display: flex;
  flex-flow: column nowrap;

  .globalInfosWrapper {
    display: flex;
    flex-flow: row wrap;
    align-items: center;

    .nameDatesWrapper {
      display: flex;
      flex-direction: row;
      align-items: center;

      .nameWrapper {
        display: flex;
        flex-direction: row;
        align-items: center;

        .logoWrapper {
          width: 4rem;
          height: 4rem;

          display: flex;
          align-items: center;
          justify-content: center;

          .logo {
            max-width: 4rem;
            max-height: 4rem;
          }
        }

        .name {
          margin-left: 1rem;

          .bodyBase1Bold();
          color: @palette-color-neutrals-dark;
        }
      }

      .createdAt {
        margin-left: 1rem;
      }

      .trialEndDate {
        margin-left: 1rem;
      }
    }

    .seats {
      margin-left: auto;
    }

    .statuses {
      margin-left: 3rem;
    }
  }

  .detailsWrapper {
    margin-top: 0.6rem;

    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
  }

  .accessIcon {
    position: absolute;
    top: -0.6rem;
    left: -0.6rem;

    width: 2.4rem;
    height: 2.4rem;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: @palette-color-neutrals-white;
    border-radius: 50%;
    border: 0.1rem solid @palette-color-border-light;

    .lock {
      .colorSvg(@palette-color-shades-secondary-red-hover);
    }

    .unlock {
      .colorSvg(@palette-color-primary-teal);
    }
  }



  &:hover, &:focus {
    border-color: @palette-color-primary-blue;
    text-decoration: none;

    .accessIcon {
      border-color: @palette-color-primary-blue;
    }
  }
}
