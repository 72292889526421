@import '~@palette/styles/typographies';
@import '~@palette/styles/colors';

.wrapper {
  .title {
    margin-top: 2.4rem;

    height: 4rem;

    display: flex;
    align-items: center;

    .title1Bold();
    color: @palette-color-neutrals-dark;
  }

  .companyCollections {
    margin-top: 2.4rem;
  }
}
