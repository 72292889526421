@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.overlay {
  :global(.ant-tooltip-arrow) {
    display: none;
  }

  :global(.ant-tooltip-inner) {
    background-color: @palette-color-neutrals-dark;
    .bodyBase2Medium();
    color: @palette-color-neutrals-white;

    .title {
      .bodyBase2Medium();
      color: @palette-color-neutrals-white;
    }

    .titleWithContent {
      .title {
        .caption1();
        color: @palette-color-neutrals-grey2;
      }

      .content {
        margin-top: 0.2rem;

        .bodyBase2Medium();
        color: @palette-color-neutrals-white;
      }
    }
  }
}
