@import '~@palette/styles/colors.less';

.accessNotAuthorizedAlert {
  width: fit-content;
  margin: 0 auto;
}

.wrapper {
  .header {
    margin-top: 2.4rem;
  }

  .details {
    margin-top: 2.4rem;

    .settingsUsersWrapper {
      margin-top: 2.4rem;

      display: flex;
      flex-direction: row;
      height: calc(100% - 10.2rem);
      max-width: 100%;
      overflow: hidden;

      .settingsWrapper {
        width: 30rem;
        border-right: 0.1rem solid @palette-color-border-light;
        padding-right: 2.4rem;
      }

      .tabsWrapper {
        flex: 1;
        max-width: 100%;
        padding-left: 2.4rem;
      }
    }
  }
}
