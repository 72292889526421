@import '~@palette/styles/palette-theme.less';

.center(@elem) {
  position: relative;

  & > @{elem} {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@keyframes spin {
  from {
    transform:rotate(0deg);
  }
  to {
    transform:rotate(360deg);
  }
}

.spin(@duration: 2000ms){
  animation-name: spin;
  animation-duration: @duration;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
