@import '~@palette/styles/typographies';
@import '~@palette/styles/colors';
@import '~@palette/styles/mixins';

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .supportOwnerWrapper {
    display: flex;
    align-items: center;
    max-width: calc(100% - 4.2rem);

    .supportOwnerIconWrapper {
      position: relative;

      .supportOwnerIcon {
        .colorSvg(@palette-color-neutrals-dark);
      }

      .supportOwnerPaletteLogo {
        max-width: 1.2rem;
        position: absolute;
        top: -0.6rem;
        right: -0.8rem;
      }
    }

    .supportOwner {
      margin-left: 1.5rem;

      .bodyBase2Medium();
      color: @palette-color-neutrals-dark;

      .ellipsis();
    }
  }

  .updateSupportOwner {
    margin-left: 1rem;
  }
}
