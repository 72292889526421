@import '~@palette/styles/typographies.less';
@import '~@palette/styles/colors.less';

.wrapper {
  .bodyBase2Medium();
  border: none;
  margin: unset;
  padding: 0.4rem 1rem;
  border-radius: 0.6rem;

  color: @palette-color-neutrals-dark;

  :global(.anticon) {
    .colorSvg(@palette-color-neutrals-dark);
  }

  &.success {
    background-color: @palette-color-fill-teal-fill;
    color: @palette-color-secondary-light-teal;

    :global(.anticon) {
      .colorSvg(@palette-color-secondary-light-teal);
    }
  }

  &.warning {
    background-color: @palette-color-fill-soft-vanilla;
    color: @palette-color-secondary-brown-yellow;

    :global(.anticon) {
      .colorSvg(@palette-color-secondary-brown-yellow);
    }
  }

  &.info {
    background-color: @palette-color-fill-sky;
    color: @palette-color-secondary-light-blue;

    :global(.anticon) {
      .colorSvg(@palette-color-secondary-light-blue);
    }
  }

  &.skyblue {
    background-color: @palette-color-shades-navy-sky-blue;
    color: @palette-color-neutrals-dark;

    :global(.anticon) {
      .colorSvg(@palette-color-neutrals-dark);
    }
  }

  &.danger {
    background-color: @palette-color-fill-soft-red;
    color: @palette-color-secondary-red;

    :global(.anticon) {
      .colorSvg(@palette-color-secondary-red);
    }
  }
}
