@import '~@palette/styles/colors.less';
@import '~@palette/styles/palette-theme.less';

.stickyCol {
  position: sticky;
  background-color: @palette-color-neutrals-white;
  z-index: 150;
}

.withShadow {
  transition: box-shadow 0.3s;

  &::after {
    content: "";
    pointer-events: none;
    position: absolute;
    top: 0;
    right: -1rem;
    bottom: 0;
    z-index: 1;
    width: 1rem;
    box-shadow: inset 1rem 0 0.8rem -0.8rem rgb(0 0 0 / 15%);
  }
}

.withOppositeShadow {
  transition: box-shadow 0.3s;

  &::after {
    content: "";
    pointer-events: none;
    position: absolute;
    top: 0;
    left: -1rem;
    bottom: 0;
    z-index: 1;
    width: 1rem;
    box-shadow: inset -1rem 0 0.8rem -0.8rem rgb(0 0 0 / 15%);
  }
}

.fixedColumnsFromRight {
  &.withShadow {
    &::after {
      left: -1rem;
      right: initial;
      box-shadow: inset -1rem 0 0.8rem -0.8rem rgb(0 0 0 / 15%);
    }
  }

  &.withOppositeShadow {
    &::after {
      right: -1rem;
      left: initial;
      box-shadow: inset 1rem 0 0.8rem -0.8rem rgb(0 0 0 / 15%);
    }
  }
}

.header {
  width: fit-content;
  border-top-left-radius: @border-radius-base;
  border-top-right-radius: @border-radius-base;
  background-color: @palette-color-neutrals-white;

  &.stretch {
    width: 100%;
  }

  .headerRow {
    display: flex;
    flex-direction: row;

    .headerCell {
      background-color: @palette-color-neutrals-white;
      border: 0.1rem solid @palette-color-border-light;
      border-left-width: 0;
      display: flex;
      align-items: center;

      &:first-child {
        border-top-left-radius: @border-radius-base;
        border-left-width: 0.1rem;
      }

      &:last-child, &.lastNotHiddenColumn {
        border-top-right-radius: @border-radius-base;
        overflow: hidden;
      }

      &.highlight {
        background-color: @palette-color-fill-teal-fill;
      }

      &.hidden {
        border: none !important;
        width: 0 !important;
        overflow: visible;

        &:first-child + .headerCell {
          border-top-left-radius: @border-radius-base;
          border-left-width: 0.1rem;
        }
      }
    }
  }

  &.borderless {
    .headerRow {
      .headerCell {
        border: none;
        border-radius: 0;
        border-bottom: 0.1rem solid @palette-color-border-light;

        &.hidden {
          border: none;
          border-radius: 0;

          + .headerCell {
            border: none;
            border-radius: 0;
            border-bottom: 0.1rem solid @palette-color-border-light;
          }
        }
      }
    }
  }

  &.stickyHeader {
    position: sticky;
    top: 0;
    z-index: 150;

    .headerRow {
      .headerCell {
        &.stickyCol {
          z-index: 150;
        }
      }
    }
  }
}
