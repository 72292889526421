@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  .bodyBase2Medium();
  color: @palette-color-primary-blue;
  text-decoration: none;

  &:hover, &:focus {
    .bodyBase2Medium();
    color: @palette-color-primary-blue;
    text-decoration: underline;
  }

  .contentWithIcon {
    display: flex;
    flex-direction: row;
    align-items: center;

    .icon {
      margin-left: 0.5rem;
      .colorSvg(@palette-color-primary-blue);
    }
  }
}
