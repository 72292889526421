@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;

  .contentWrapper {
    display: flex;
    flex-direction: column;

    .title {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .subtitle {
      margin-top: 0.5rem;
      display: flex;
      flex-direction: row;
      align-items: center;

      .title2Medium();
      color: @palette-color-neutrals-dark;
    }
  }
}
