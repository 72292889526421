.wrapper {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;

  .roleFilter {
    margin-bottom: 1rem;
  }

  .statusFilter {
    margin-left: 1rem;
    margin-bottom: 1rem;
  }
}
