@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  border-radius: 1.4rem;
  border-width: 0;
  padding: 1.6rem 2rem;

  align-items: flex-start;

  .bodyBase2Medium();

  &:global(.ant-alert-with-description) :global(.ant-alert-message), &:global(.ant-alert-with-description) :global(.ant-alert-description), :global(.ant-alert-message) {
    color: inherit;
  }

  &:global(.ant-alert-with-description) :global(.ant-alert-message) {
    .bodyBase2Bold();
    margin-bottom: 2rem;
  }

  &:global(.ant-alert-with-description) {
    &:global(.ant-alert-no-icon) {
      padding: 1.6rem 2rem;
    }
  }

  &:global(.ant-alert-success) {
    background-color: @palette-color-fill-teal-fill;
    color: @palette-color-secondary-light-teal;

    > :global(.anticon) {
      .colorSvg(@palette-color-secondary-light-teal);
    }
  }

  &:global(.ant-alert-info) {
    background-color: @palette-color-fill-sky;
    color: @palette-color-secondary-light-blue;

    > :global(.anticon) {
      .colorSvg(@palette-color-secondary-light-blue);
    }

    &.neutral {
      background-color: @palette-color-fill-lavender;
      color: @palette-color-secondary-lavender;

      > :global(.anticon) {
        .colorSvg(@palette-color-secondary-lavender);
      }
    }
  }

  &:global(.ant-alert-warning) {
    background-color: @palette-color-fill-soft-vanilla;
    color: @palette-color-secondary-brown-yellow;

    > :global(.anticon) {
      .colorSvg(@palette-color-secondary-brown-yellow);
    }
  }

  &:global(.ant-alert-error) {
    background-color: @palette-color-fill-light-red;
    color: @palette-color-banners-red;

    > :global(.anticon) {
      .colorSvg(@palette-color-banners-red);
    }
  }
}
