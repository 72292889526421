@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  :global(.ant-popover-arrow) {
    display: none;
  }

  :global(.ant-popover-inner) {
    background-color: @palette-color-neutrals-white;

    border: 0.1rem solid @palette-color-border-light;
    box-sizing: border-box;
    box-shadow: 0 1.5rem 8.4rem 0.3rem fade(@palette-color-neutrals-grey3, 1%);
    border-radius: 2.4rem;

    :global(.ant-popover-inner-content) {
      padding: 3rem 3.2rem;
    }
  }
}
