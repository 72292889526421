@import '~@palette/styles/colors.less';
@import '~@palette/styles/fonts.less';
@import '~@palette/styles/typographies.less';
@import '~@palette/styles/palette-theme.less';

* {
  box-sizing: border-box;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, main, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;

  background-color: transparent;
}

html {
  font-size: 62.5%; /* Now 10px = 1rem! */
}

body {
  font-size: @font-size-base; /* px fallback */
  font-size: @font-size-base-rem; /* default font-size for document */

  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
}

a {
  transition: none;
}

article, aside, details, figcaption, figure, footer, header, main, nav, section {
  display: block;
}

ol, ul, li {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, q:before,
blockquote:after, q:after {
  content: '';
  content: none;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

h1 {
  .headlineH1();
}

h2 {
  .headlineH2();
}

h3 {
  .headlineH3();
}

#root{
  min-height: 100vh;
  min-width: 100vw;
}

*::selection {
  color: @palette-color-neutrals-white;
  background-color: @palette-color-primary-blue;
}

/* CSS Class Helpers and Overrides (reviewed) */
.paletteNotification {
  .ant-message-notice-content {
    box-shadow: @palette-shadow-1;
    border: 0.1rem solid @palette-color-border-light;
    border-radius: 2rem;
    max-width: 60rem;
  }

  .ant-message-custom-content {
    display: flex;
    align-items: center;

    .bodyBase1Medium();
    color: @palette-color-neutrals-grey1;
  }
}
