@import '~@palette/styles/typographies';
@import '~@palette/styles/colors';

.wrapper {
  display: flex;
  flex-direction: column;

  .titleWrapper {
    .title {
      padding: 2.4rem 0;

      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .title1Bold();
      color: @palette-color-neutrals-dark;

      border-bottom: 0.1rem solid @palette-color-border-light;
    }
  }

  .content {
    margin-top: 2.4rem;
  }
}
