.wrapper {
  .filtersWrapper {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
  }

  .list {
    margin-top: 1.4rem;
    height: calc(100vh - 28rem);
  }

  .pagination {
    margin-top: 4.4rem;
  }
}
