@import '~@palette/styles/colors.less';

.wrapper {
  .byPaletteAdmin {
    .colorSvg(@palette-color-primary-teal);
  }

  .notByPaletteAdmin {
    .colorSvg(@palette-color-shades-secondary-red-hover);
  }
}
