@import '~@palette/styles/typographies.less';
@import '~@palette/styles/colors.less';

.wrapper {
  display: flex;
  align-items: center;

  .moreMenu {
    margin-left: 1rem;
  }
}
