@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';
@import '~@palette/styles/mixins.less';

.wrapper {
  .ellipsis();
  .bodyBase2Medium();
  color: @palette-color-primary-blue;
  text-decoration: none;

  &:hover, &:focus {
    .bodyBase2Medium();
    color: @palette-color-primary-blue;
    text-decoration: underline;
  }

  .content {
    .ellipsis();
  }

  .contentWithIcon {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: fit-content;

    .icon {
      margin-right: 0.5rem;
      .colorSvg(@palette-color-primary-blue);
    }

    &.iconIsAfter {
      flex-direction: row-reverse;

      .icon {
        margin-right: 0;
        margin-left: 0.5rem;
      }
    }
  }

  &.secondary {
    .bodyBase2Medium();
    color: @palette-color-neutrals-grey1;
    text-decoration: none;

    .contentWithIcon {
      .icon {
        .colorSvg(@palette-color-neutrals-grey1);
      }
    }

    &:hover, &:focus {
      .bodyBase2Medium();
      color: @palette-color-primary-blue;
      text-decoration: underline;

      .contentWithIcon {
        .icon {
          .colorSvg(@palette-color-primary-blue);
        }
      }
    }
  }

  &.disabled {
    cursor: not-allowed;
  }
}
