@import '~@palette/styles/typographies.less';
@import '~@palette/styles/colors.less';

.wrapper {
  .title {
    margin: 2rem 0;

    .bodyBase1Bold();
    color: @palette-color-neutrals-dark;
  }

  .user {
    margin-top: 2rem;
  }
}
