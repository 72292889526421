.modal {
  :global(.ant-modal) {
    min-width: 95rem;
    max-width: 95rem;
  }

  .jsonWrapper {
    overflow: hidden;

    pre {
      white-space: pre-wrap;
    }
  }
}
