@import '~@palette/styles/palette-theme.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  display: flex;
  align-items: center;

  .bodyBase2Medium();
  color: @palette-color-neutrals-grey1;

  .iconFilter {
    margin-right: .2rem;
    .colorSvg(@palette-color-neutrals-grey1);
  }

  .iconCloseFilter {
    margin: .3rem .4rem .3rem 0;
    .colorSvg(@palette-color-neutrals-grey1);
  }

  &:hover {
    color: @palette-color-primary-blue;
    cursor: pointer;

    .iconFilter {
      .colorSvg(@palette-color-primary-blue);
    }

    .iconCloseFilter {
      .colorSvg(@palette-color-primary-blue);
    }
  }
}
