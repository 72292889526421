@import '~@palette/styles/typographies';
@import '~@palette/styles/colors';

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  .trialEndDateIcon {
    .colorSvg(@palette-color-secondary-brown-yellow);
  }

  .trialEndDate {
    margin-left: 0.6rem;

    .caption1();
    color: @palette-color-secondary-brown-yellow;
  }
}
