@import '~@palette/styles/typographies';
@import '~@palette/styles/colors';

.wrapper {
  .filtersWrapper {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;

    .userSearch {
      width: 22rem;
      min-width: 22rem;

      margin-bottom: 1rem;
    }

    .sortOptions {
      margin-left: 1rem;
      margin-bottom: 1rem;
    }
  }

  .list {
    margin-top: 1.4rem;
  }
}
