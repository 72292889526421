@import '~@palette/styles/typographies';
@import '~@palette/styles/colors';

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;

  .usersCountWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;

    .usersCountIcon {
      .colorSvg(@palette-color-primary-blue);
    }

    .usersCount {
      margin-left: 0.6rem;

      .bodyBase1Bold();
      color: @palette-color-primary-blue;
    }
  }

  .minSeatsWrapper {
    margin-left: 1rem;
  }

  .seatsCountWrapper {
    margin-left: 1rem;

    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;

    .seatsCountIcon {
      .colorSvg(@palette-color-primary-teal);
    }

    .seatsCount {
      margin-left: 0.6rem;

      .bodyBase1Bold();
      color: @palette-color-primary-teal;
    }
  }
}
