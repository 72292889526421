@import '~@palette/styles/typographies';
@import '~@palette/styles/colors';

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .email {
    .bodyBase1Medium();
    color: @palette-color-neutrals-dark;
  }

  .removeBtn {
    margin-left: 1rem;
  }
}
