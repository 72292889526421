@import '~@palette/styles/typographies.less';
@import '~@palette/styles/colors.less';

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  :global(.ant-upload-picture-card-wrapper) {
    width: auto;

    :global(.ant-upload) {
      &:global(.ant-upload-select-picture-card) {
        margin-right: 0;
        margin-bottom: 0;

        &:hover {
          border-color: @palette-color-primary-blue;
        }
      }
    }
  }

  .uploaderDeleteWrapper {
    position: relative;

    .uploadedPictureImg {
      width: calc(100% - 0.4rem);
      border-radius: 0.8rem;
    }

    .uploadWrapper {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: space-between;
      min-height: 4.5rem;

      .iconUpload {
        .colorSvg(@palette-color-primary-blue);
      }

      .labelUpload {
        margin-top: 0.8rem;
        color: @palette-color-primary-blue;
        .bodyBase2Medium();
      }
    }

    .deleteBtn {
      position: absolute;
      bottom: -1rem;
      left: -1rem;
      background-color: @palette-color-neutrals-white;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.6rem;
      margin: 0;
      height: auto;
      width: auto;

      &:hover, &:focus, &:active {
        background-color: @palette-color-neutrals-white;
      }
    }
  }

  .alert {
    margin-top: 2rem;
    width: 100%;

    :global(.ant-alert-message) {
      display: flex;
      flex-direction: column;
    }
  }
}
