@import '~@palette/styles/colors';

.wrapper {
  width: 100%;

  .displayModeWrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .syncIntervalWrapper {
      width: 100%;
      display: flex;
      align-items: center;

      .syncIntervalIcon {
        margin-right: 1rem;
        .colorSvg(@palette-color-primary-blue);
      }
    }
  }

  .editModeWrapper {
    .actions {
      display: flex;
      flex-direction: column;
      align-items: center;

      .updateBtn {
        width: 100%;
        justify-content: center;
      }
    }
  }
}
