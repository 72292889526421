@import '~@palette/styles/typographies';
@import '~@palette/styles/colors';

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;

  .createdAt {
    margin-left: 0.6rem;

    .caption1Italic();
    color: @palette-color-neutrals-grey2;
  }
}
