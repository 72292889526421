@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  width: 100%;

  background-color: @palette-color-fill-table-group;
  border-radius: 1.2rem;

  padding: 1.6rem 2.4rem;

  display: flex;
  flex-direction: column;
  align-items: center;

  .titleWrapper {
    display: flex;
    align-items: center;

    .bodyBase1Medium();
    color: @palette-color-neutrals-grey2;

    .icon {
      margin-right: 0.4rem;
      margin-bottom: 0.1rem;

      .colorSvg(@palette-color-neutrals-grey2);
    }
  }

  .rules {
    margin-top: 1.2rem;

    display: flex;
    flex-direction: column;

    .ruleWrapper {
      & + .ruleWrapper {
        margin-top: 0.4rem;
      }

      display: flex;
      align-items: center;

      .checkIcon {
        margin-right: 0.4rem;

        .colorSvg(@palette-color-secondary-white-grape);
      }

      .alertIcon {
        margin-right: 0.4rem;

        .colorSvg(@palette-color-secondary-red);
      }

      .rule {
        .bodyBase2Medium();
        color: @palette-color-neutrals-grey1;
      }
    }
  }
}
