@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  min-height: 6rem;

  .icon {
    .colorSvg(@palette-color-secondary-light-blue);
    margin: -2rem 0 0 -1.2rem !important;
  }

  :global(.ant-spin-text) {
    .bodyBase2Medium();
    color: @palette-color-secondary-light-blue;
  }

  .empty {
    height: 10rem;
  }
}
