@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  max-width: 30.8rem;

  padding: 1rem 0;
  border-radius: 1rem;
  border: 0.1rem solid @palette-color-border-normal;
  text-decoration: none;

  span {
    .bodyBase1Bold();
    color: @palette-color-neutrals-grey1;
  }

  svg {
    margin-right: 1.6rem;
  }

  &:hover {
    border-color: @palette-color-primary-blue;
  }
}

.errorAlert {
  width: fit-content;
  margin: 1rem auto;
}
