@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';

.loaderWrapper {
  height: 4rem;
  padding: 0.8rem 1.6rem;
  border: 0.2rem solid @palette-color-border-normal;
  border-radius: 1.2rem;

  &.big {
    height: 5rem;
    padding: 1.3rem 1.6rem;
  }

  &.borderLess {
    height: 1.8rem;
    padding: 0 1.6rem 0 0;
    border: none;
  }

  .loaderIconWrapper {
    height: 2rem;

    .loaderIcon {
      .colorSvg(@palette-color-primary-blue);
    }
  }
}

.wrapper {
  .inputValue();
  color: @palette-color-neutrals-dark;

  &:global(:not(.ant-select-customize-input)) {
    :global(.ant-select-selector) {
      height: 4rem;
      padding: 0.8rem 1.6rem;
      border: 0.2rem solid @palette-color-border-normal;
      border-radius: 1.2rem;
    }
  }

  :global(.ant-select-arrow) {
    margin-top: -1.2rem;
    width: 2.4rem;
    height: 2.4rem;

    span {
      .colorSvg(@palette-color-neutrals-grey1);
    }
  }

  :global(.ant-select-clear) {
    margin-top: -0.9rem;
    width: 2.4rem;
    height: 2.4rem;
    right: 3.5rem;
    opacity: 1;

    span {
      .colorSvg(@palette-color-neutrals-grey2) !important;

      &:hover {
        .colorSvg(@palette-color-primary-blue) !important;
      }
    }
  }

  :global(.ant-select-selector) {
    height: 4rem;
    padding: 0.8rem 1.6rem;
    border: 0.2rem solid @palette-color-border-normal;
    border-radius: 1.2rem;

    :global(.ant-select-selection-item) {
      .inputValue();
      color: @palette-color-neutrals-dark;

      :global(span) {
        br {
          display: none;
        }
      }
    }

    :global(.ant-select-selection-placeholder) {
      .inputValue();
      color: @palette-color-neutrals-grey2;
      left: 1.6rem;
      right: 1.6rem;
    }

    :global(.ant-select-selection-search) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      left: 1.6rem;
    }

    :global(.ant-select-selection-overflow) {
      overflow: auto;
      flex-wrap: initial;
    }
  }

  &.big {
    &:global(:not(.ant-select-customize-input)) {
      :global(.ant-select-selector) {
        height: 5rem;
        padding: 1.3rem 1.6rem;
      }
    }

    :global(.ant-select-selector) {
      height: 5rem;
      padding: 1.3rem 1.6rem;
    }
  }

  &:global(.ant-select-open) {
    :global(.ant-select-selector) {
      :global(.ant-select-selection-item) {
        color: @palette-color-neutrals-grey2;
      }
    }

    :global(.ant-select-arrow) {
      margin-top: -1.2rem;
      width: 2.4rem;
      height: 2.4rem;
      transform: rotate(180deg);

      span {
        .colorSvg(@palette-color-primary-blue);
      }
    }
  }

  &:global(:not(.ant-select-disabled)) {
    &:hover {
      :global(.ant-select-selector) {
        border-color: @palette-color-shades-navy-cloudy-blue;
        border-right-width: 0.2rem !important;
      }
    }

    &:focus {
      :global(.ant-select-selector) {
        border-color: @palette-color-primary-blue;
        border-right-width: 0.2rem !important;
        box-shadow: none;
      }
    }
  }

  &:global(.ant-select-focused:not(.ant-select-disabled):not(.ant-select-customize-input)) {
    :global(.ant-select-selector) {
      border-color: @palette-color-primary-blue;
      border-right-width: 0.2rem !important;
      box-shadow: none;
    }
  }

  &.borderLess {
    &:global(:not(.ant-select-customize-input)) {
      :global(.ant-select-selector) {
        height: 1.8rem;
        padding: 0 1.6rem 0 0;
        border: none;
      }
    }

    :global(.ant-select-selector) {
      height: 1.8rem;
      padding: 0 1.6rem 0 0;
      border: none;

      :global(.ant-select-selection-item) {
        .bodyBase2Medium();
        color: @palette-color-neutrals-dark;

        &:hover {
          color: @palette-color-primary-blue;
        }
      }

      :global(.ant-select-selection-placeholder) {
        .bodyBase2Medium();
        color: @palette-color-neutrals-grey2;
      }
    }
  }

  &:global(.ant-select-multiple) {

    :global(.ant-select-selector) {
      display: block;
    }

    :global(.ant-select-selection-item) {
      .inputValue();
      color: @palette-color-neutrals-dark;

      height: 2.4rem;
      margin: 0;
      background-color: @palette-color-fill-sky;
      border: none;
      border-radius: 0.8rem;
      margin-right: 0.4rem;
      align-items: center;
    }
  }

  &.tags {
    :global(.ant-select-selection-search) {
      left: 0;
      margin: 0;
    }
  }
}

.dropdown {
  border: 0.14rem solid @palette-color-border-light;
  box-shadow: @palette-shadow-1;
  border-radius: 1.2rem;
  padding: 1rem;

  :global(.ant-select-item-option) {
    background-color: transparent;
    padding: 0 0 0.3rem 0;

    &:last-child {
      padding-bottom: 0;
    }

    :global(.ant-select-item-option-content) {
      padding: 0.8rem 1.4rem;
      border-radius: @border-radius-base;

      .inputValue();
      color: @palette-color-neutrals-grey1;
    }
  }

  :global(.ant-select-item-option-selected) {
    :global(.ant-select-item-option-content) {
      background-color: @palette-color-fill-sky;
      color: @palette-color-primary-blue;
    }

    :global(.ant-select-item-option-state) {
      :global(.anticon) {
        font-size: 1.6rem;
        color: @palette-color-primary-blue;
        .colorSvg(@palette-color-primary-blue);
      }
    }
  }

  :global(.ant-select-item-option-active) {
    :global(.ant-select-item-option-content) {
      background-color: @palette-color-fill-sky;
    }
  }

  .not_found_content_description {
    .caption1();
    color: @palette-color-neutrals-grey1;
    text-align: center;
  }

  .not_found_content_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .add_to_options_wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    .search_value {
      .inputValue();
      color: @palette-color-neutrals-grey1;
    }
  }

  &.dropdownBorderLess {
    border: none;
    box-shadow: @palette-shadow-2;
    padding: 1.2rem 1.4rem;
    width: fit-content !important;

    :global(.ant-select-item-option) {
      :global(.ant-select-item-option-content) {
        padding: 0.2rem 1.2rem;
        .bodyBase2Medium();
        color: @palette-color-neutrals-grey1;
        display: inline-flex;
        align-items: center;
      }
    }

    :global(.ant-select-item-option-selected) {
      :global(.ant-select-item-option-content) {
        color: @palette-color-primary-blue;
      }

      :global(.ant-select-item-option-state) {
        :global(.anticon) {
          color: @palette-color-primary-blue;
          .colorSvg(@palette-color-primary-blue);
        }
      }
    }

    :global(.ant-select-item-option-active) {
      :global(.ant-select-item-option-content) {
        color: @palette-color-primary-blue;
      }
    }

    &.dropdownBorderLessOneOption {
      :global(.ant-select-item-option-selected) {
        :global(.ant-select-item-option-content) {
          background-color: transparent;
          color: @palette-color-primary-blue;

          :global(.ant-select-item-option-state) {
            :global(.anticon) {
              color: @palette-color-primary-blue;
              .colorSvg(@palette-color-primary-blue);
            }
          }

          &:hover {
            background-color: @palette-color-fill-sky;
          }
        }
      }

      :global(.ant-select-item-option-active) {
        :global(.ant-select-item-option-content) {
          background-color: transparent;
          color: @palette-color-neutrals-grey1;

          :global(.ant-select-item-option-state) {
            :global(.anticon) {
              color: @palette-color-neutrals-grey1;
              .colorSvg(@palette-color-neutrals-grey1);
            }
          }

          &:hover {
            background-color: @palette-color-fill-sky;
            color: @palette-color-primary-blue;

            :global(.ant-select-item-option-state) {
              :global(.anticon) {
                color: @palette-color-primary-blue;
                .colorSvg(@palette-color-primary-blue);
              }
            }
          }
        }
      }
    }
  }

  &.dropdownTags {
    :global(.ant-select-item-option-selected) {
      :global(.ant-select-item-option-content) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      :global(.ant-select-item-option-state) {
        display: flex;
        align-items: center;

        padding-right: 1.4rem;

        background-color: @palette-color-fill-sky;
        border-top-right-radius: @border-radius-base;
        border-bottom-right-radius: @border-radius-base;

        :global(.anticon) {
          color: @palette-color-primary-blue;
          .colorSvg(@palette-color-primary-blue);
        }
      }
    }
  }
}
