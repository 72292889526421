@import '~@palette/styles/typographies';
@import '~@palette/styles/colors';

.wrapper {
  display: flex;
  flex-direction: column;

  .displayModeWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    .noTrialSet {
      .inputLabel();
      color: @palette-color-neutrals-grey1;
    }

    .trialDataWrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .trialPaymentUrlWrapper {
        margin-top: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;

        .noTrialPaymentUrlSet {
          .caption1Italic();
          color: @palette-color-neutrals-grey1;
        }

        .trialPaymentUrlLabel {
          .inputLabel();
          color: @palette-color-neutrals-grey1;
        }

        .trialPaymentUrl {
          .inputLabel();
          color: @palette-color-neutrals-dark;
        }
      }
    }

    .enableEditModeButton {
      margin-top: 0.7rem;
    }

    .deleteTrialSettingsBtn {
      margin-top: 0.7rem;
    }
  }

  .editModeWrapper {
    .trialEndDateDatePicker {
      width: 100%;
    }

    .actions {
      display: flex;
      flex-direction: column;
      align-items: center;

      .updateTrialSettingsBtn {
        width: 100%;
        justify-content: center;
      }
    }
  }
}
