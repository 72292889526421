@import '~@palette/styles/colors';

.wrapper {
  display: flex;
  flex-direction: column;

  .item {
    &:first-child {
      padding-bottom: 2.4rem;
    }

    & + .item {
      position: relative;
      padding: 2.4rem 0;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        height: 0.1rem;
        width: 75%;
        transform: translateX(-50%);
        background-color: @palette-color-border-light;
      }
    }
  }
}
