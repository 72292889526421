@import '~@palette/styles/colors.less';
@import '~@palette/styles/typographies.less';
@import '~@palette/styles/palette-theme.less';
@import '~@palette/styles/mixins.less';

.wrapper {
  .contentWrapper {
    .title2Medium();
    color: @palette-color-neutrals-grey1;

    border-radius: 0.5rem;
    height: 2.4rem !important;
    width: 2.4rem !important;

    background-color: @palette-color-neutrals-grey2;

    .center(svg);

    &.icon {
      .colorSvg(@palette-color-neutrals-grey1)
    }

    &:hover {
      cursor: pointer;

      background-color: @palette-color-primary-blue;

      &.icon {
        .colorSvg(@palette-color-neutrals-white)
      }
    }
  }
}

.overlay {
  :global(.ant-dropdown-menu) {
    padding: 1.2rem 1.4rem;

    :global(.ant-dropdown-menu-item) {
      padding: 0.9rem 1.2rem;

      .menuItem {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: @palette-color-neutrals-grey1;

        .menuItemIcon {
          margin-right: 1rem;

          .bodyBase2Medium();
          color: inherit;

          height: 1.6rem;
          width: 1.6rem;

          :global(.anticon) {
            .colorSvg(@palette-color-neutrals-grey2);
          }
        }

        .menuItemTitle {
          .bodyBase2Medium();
          color: inherit;
          height: auto;
          width: 100%;
        }
      }

      &:global(.ant-dropdown-menu-item-active) {
        background-color: @palette-color-fill-sky;
        border-radius: @border-radius-base;

        .menuItem {
          color: @palette-color-primary-blue;

          .menuItemIcon {
            :global(.anticon) {
              .colorSvg(@palette-color-primary-blue);
            }
          }
        }
      }
    }
  }
}
