@import '~@palette/styles/typographies';
@import '~@palette/styles/colors';

.wrapper {
  display: flex;
  flex-direction: column;

  .displayModeWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    .billingDataWrapper {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .subscriptionIdWrapper {
        display: flex;
        align-items: center;
        margin: 0.5rem 1rem 0 0;

        .stripeIcon {
          width: 2rem;
          height: 2rem;
        }

        .subscriptionIdLabel {
          margin-left: 1rem;

          .inputLabel();
          color: @palette-color-neutrals-dark;
          word-break: break-all;
        }
      }

      .minSeats {
        margin-top: 0.5rem;
      }
    }

    .enableEditModeButton {
      margin-top: 0.7rem;
    }
  }

  .editModeWrapper {
    .actions {
      display: flex;
      flex-direction: column;
      align-items: center;

      .updateBillingSettingsBtn {
        width: 100%;
        justify-content: center;
      }
    }
  }
}
