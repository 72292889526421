.wrapper {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .companiesList {
    margin-top: 2.4rem;
  }
}
