@import '~@palette/styles/typographies';
@import '~@palette/styles/colors';

.popoverContentWrapper {
  display: flex;
  flex-direction: column;

  .paletteAdmin + .paletteAdmin {
    margin-top: 0.5rem;
  }
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .paletteAdminsCountTag:hover {
    cursor: pointer;
  }
}
