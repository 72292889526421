@import '~@palette/styles/typographies';
@import '~@palette/styles/colors';

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  .minSeatsIcon {
    .colorSvg(@palette-color-secondary-lavender);
  }

  .minSeats {
    margin-left: 0.6rem;

    .bodyBase1Medium();
    color: @palette-color-secondary-lavender;
  }
}
