/* Font weights */
@palette-font-weight-thin: 100;
@palette-font-weight-extra-light: 200;
@palette-font-weight-light: 300;
@palette-font-weight-regular: 400;
@palette-font-weight-medium: 500;
@palette-font-weight-semi-bold: 600;
@palette-font-weight-bold: 700;
@palette-font-weight-extra-bold: 800;
@palette-font-weight-black: 900;

/* Font mixins */
.declareFont(@name, @path, @weight: @palette-font-weight-regular, @style: 'normal') {
  @font-face {
    font-family: @name;
    src: url('@{path}.eot');
    src: url('@{path}.eot?#iefix') format('embedded-opentype'),
    url('@{path}.woff2') format('woff2'),
    url('@{path}.woff') format('woff'),
    url('@{path}.ttf') format('truetype');
    font-weight: @weight;
    font-style: @style;
    font-display: swap;
  }
}

/* Font declarations */
.declareFont('Satoshi', '/public/fonts/Satoshi/Satoshi-LightItalic', @palette-font-weight-light, 'italic');
.declareFont('Satoshi', '/public/fonts/Satoshi/Satoshi-Light', @palette-font-weight-light);
.declareFont('Satoshi', '/public/fonts/Satoshi/Satoshi-Italic', @palette-font-weight-regular, 'italic');
.declareFont('Satoshi', '/public/fonts/Satoshi/Satoshi-Regular', @palette-font-weight-regular);
.declareFont('Satoshi', '/public/fonts/Satoshi/Satoshi-MediumItalic', @palette-font-weight-medium, 'italic');
.declareFont('Satoshi', '/public/fonts/Satoshi/Satoshi-Medium', @palette-font-weight-medium);
.declareFont('Satoshi', '/public/fonts/Satoshi/Satoshi-BoldItalic', @palette-font-weight-bold, 'italic');
.declareFont('Satoshi', '/public/fonts/Satoshi/Satoshi-Bold', @palette-font-weight-bold);
.declareFont('Satoshi', '/public/fonts/Satoshi/Satoshi-BlackItalic', @palette-font-weight-black, 'italic');
.declareFont('Satoshi', '/public/fonts/Satoshi/Satoshi-Black', @palette-font-weight-black);

.declareFont('SpaceMono', '/public/fonts/SpaceMono/SpaceMono-Regular', @palette-font-weight-regular);
