@import '~@palette/styles/colors.less';
@import '~@palette/styles/palette-theme.less';

.row {
  display: flex;
  flex-direction: row;

  .cell {
    border: 0.1rem solid @palette-color-border-light;
    border-left-width: 0;
    border-top-width: 0;
    display: flex;
    align-items: center;
    background-color: @palette-color-neutrals-white;
    transition: box-shadow 0.3s;

    &:first-child {
      border-left-width: 0.1rem;
    }

    &.stickyCell {
      position: sticky;
      background-color: @palette-color-neutrals-white;
      z-index: 100;
    }

    &.highlight {
      background-color: @palette-color-fill-teal-fill;
    }

    &.withShadow {
      transition: box-shadow 0.3s;

      &::after {
        content: "";
        pointer-events: none;
        position: absolute;
        top: 0;
        right: -1rem;
        bottom: 0;
        z-index: 1;
        width: 1rem;
        box-shadow: inset 1rem 0 0.8rem -0.8rem rgb(0 0 0 / 15%);
      }
    }

    &.withOppositeShadow {
      transition: box-shadow 0.3s;

      &::after {
        content: "";
        pointer-events: none;
        position: absolute;
        top: 0;
        left: -1rem;
        bottom: 0;
        z-index: 1;
        width: 1rem;
        box-shadow: inset -1rem 0 0.8rem -0.8rem rgb(0 0 0 / 15%);
      }
    }

    &.fixedColumnsFromRight {

      &.withShadow {

        &::after {
          left: -1rem;
          right: initial;
          box-shadow: inset -1rem 0 0.8rem -0.8rem rgb(0 0 0 / 15%);
        }
      }

      &.withOppositeShadow {
        &::after {
          right: -1rem;
          left: initial;
          box-shadow: inset 1rem 0 0.8rem -0.8rem rgb(0 0 0 / 15%);
        }
      }
    }

    &.hidden {
      border: none !important;
      width: 0 !important;

      + .cell {
        border-left-width: 0.1rem;
      }
    }
  }

  &:last-child {
    .cell {
      &:first-child {
        border-bottom-left-radius: @border-radius-base;
      }

      &:last-child {
        border-bottom-right-radius: @border-radius-base;
      }
    }
  }

  &.highlightOnHover:hover {
    .cell {
      background-color: @palette-color-fill-sky;

      &.stickyCell {
        background-color: @palette-color-fill-sky;
      }

      &.highlight {
        background-color: @palette-color-fill-sky;
      }

      > * {
        background-color: transparent;
      }
    }
  }

  &.hasFooters:last-child {
    .cell {
      border-bottom-width: 0;

      &:first-child {
        border-bottom-left-radius: 0;
      }

      &:last-child {
        border-bottom-right-radius: 0;
      }
    }
  }

  &.borderless {
    > .cell {
      border: none;
      border-bottom: 0.1rem solid @palette-color-border-light;

      &.hidden {
        border: none;

        + .cell {
          border: none;
          border-bottom: 0.1rem solid @palette-color-border-light;
        }
      }
    }
  }

  &.withExpand {
    flex-direction: column;

    .cellsWrapper {
      display: flex;
      flex-direction: row;

      .cell {
        border-bottom-width: 0;
      }
    }

    .expandWrapper {
      width: 100%;
      padding: 0 0 2rem;
      border-bottom: 0.1rem solid @palette-color-border-light;
    }

    &.borderless {
      .cellsWrapper {
        > .cell {
          border: none;
        }
      }
    }
  }
}
