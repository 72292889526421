@import '~@palette/styles/palette-theme.less';
@import '~@palette/styles/typographies.less';

.wrapper {
  .filtersWrapper {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;

    .companySearch {
      width: 28rem;
      min-width: 28rem;

      margin-bottom: 1rem;
    }

    .filtersSortOptionsWrapper {
      display: flex;
      flex-flow: row wrap;
      align-items: center;

      margin-bottom: 1rem;

      .sortOptions {
        margin-left: 1rem;
      }
    }
  }

  .listCallFilters {
    margin-top: 1.4rem;

    display: flex;
    flex-flow: row wrap;
    align-items: center;

    .listCallFilter + .listCallFilter {
      margin-left: 0.5rem;
    }

    .resetFiltersButton {
      margin-left: 1rem;
    }
  }

  .list {
    margin-top: 1.4rem;
  }

  .pagination {
    margin-top: 4.4rem;
  }
}
