@import '~@palette/styles/typographies';
@import '~@palette/styles/colors';

.currenciesList {
  display: flex;
  flex-flow: row wrap;

  .currency {
    margin: 0.5rem;
  }
}

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;

  .timezoneWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;

    .timezoneIcon {
      .colorSvg(@palette-color-neutrals-dark);
    }

    .timezone {
      margin-left: 0.6rem;

      .bodyBase1Medium();
      color: @palette-color-neutrals-dark;
    }
  }

  .statementStrategyWrapper {
    margin-left: 2rem;

    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;

    .statementStrategyIcon {
      .colorSvg(@palette-color-neutrals-dark);
    }

    .statementStrategy {
      margin-left: 0.6rem;

      .bodyBase1Medium();
      color: @palette-color-neutrals-dark;
    }
  }

  .currenciesWrapper {
    margin-left: 2rem;

    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;

    .currenciesIcon {
      .colorSvg(@palette-color-neutrals-dark);
    }

    .currencies {
      margin-left: 0.6rem;

      .bodyBase1Medium();
      color: @palette-color-neutrals-dark;
    }
  }
}
