@import '~@palette/styles/typographies';
@import '~@palette/styles/colors';

.wrapper {
  display: flex;
  flex-direction: column;

  .feature {
    display: flex;
    flex-direction: row;
    align-items: center;

    & + .feature {
      margin-top: 1rem;
    }

    .featureSwitch {
      margin-right: 0.7rem;
    }

    .featureLabel {
      .inputLabel();
      color: @palette-color-neutrals-dark;
    }
  }
}
