@import '~@palette/styles/fonts.less';

.wrapper {
  display: flex;
  flex-direction: column;

  .jobStats {
    margin: 0.5rem;

    .jobStatsDescription {
      .jobType {
        font-weight: @palette-font-weight-black;
      }
    }
  }
}
