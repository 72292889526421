@import '~@palette/styles/typographies';
@import '~@palette/styles/colors';

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .liveStatusWrapper {
    display: flex;
    align-items: center;

    .liveIcon {
      .colorSvg(@palette-color-primary-teal);
    }

    .notLiveIcon {
      .colorSvg(@palette-color-shades-secondary-red-hover);
    }
  }

  .liveStatusLabel {
    margin-left: 1rem;

    .bodyBase1Bold();
    color: @palette-color-shades-secondary-red-hover;

    &.isLive {
      color: @palette-color-primary-teal;
    }
  }
}
