// Neutrals
@palette-color-black: #000000;
@palette-color-neutrals-dark: #0A1F38;
@palette-color-neutrals-white: #FFFFFF;
@palette-color-neutrals-grey1: #5F758E;
@palette-color-neutrals-grey2: #A6B6C9;
@palette-color-neutrals-grey3: #1C3C4E;

// Shades
@palette-color-shades-teal-hover: #0B7E69;
@palette-color-shades-teal-pressed: #016654;
@palette-color-shades-blue-hover: #0867B2;
@palette-color-shades-primary-blue-hover: #0273CC;
@palette-color-shades-secondary-red-hover: #F1575C;
@palette-color-shades-blue-pressed: #064D85;
@palette-color-shades-sea-blue: #00718C;
@palette-color-shades-light-sea-blue: #458BA7;
@palette-color-shades-navy-sky-blue: #C0E2F0;
@palette-color-shades-navy-cloudy-blue: #6AA7BF;
@palette-color-shades-cg-blue: #047D9A;
@palette-color-shades-primary-teal-ghost: #B4DCD5;
@palette-color-shades-soft-orange: #FFF7E2;

// Primary
@palette-color-primary-blue: #128BEB;
@palette-color-primary-teal: #12957D;

// Secondary
@palette-color-secondary-orange: #FF8E75;
@palette-color-secondary-brown-yellow: #D09361;
@palette-color-secondary-red: #FC6C71;
@palette-color-secondary-white-grape: #65A06F;
@palette-color-secondary-light-teal: #6AB1A4;
@palette-color-secondary-light-blue: #5BAAE8;
@palette-color-secondary-lavender: #9D9EBC;
@palette-color-secondary-grass-green: #95E499;

// Border
@palette-color-border-light: #E8EFF0;
@palette-color-border-normal: #DEE8E9;
@palette-color-border-input-error: #FF4D4F;

// Fill
@palette-color-fill-sky: #EEF9FC;
@palette-color-fill-soft-vanilla: #FFF8EE;
@palette-color-fill-light-red: #FFCFD1;
@palette-color-fill-white-grape: #EBFDEE;
@palette-color-fill-teal-fill: #F2FFF4;
@palette-color-fill-grey: #F6F8FA;
@palette-color-fill-table-group: #F8FAFE;
@palette-color-fill-table-hover: #C3D4D7;
@palette-color-fill-highlight: #E0E2FF;
@palette-color-fill-turquoise: #5ED1D8;
@palette-color-fill-lavender: #F2F3FF;
@palette-color-fill-cultured: #F6F7F9;
@palette-color-fill-antiflash-white: #EDF2F2;
@palette-color-fill-soft-red: #FEF0F1;

// Banners
@palette-color-banners-yellow: #F5C931;
@palette-color-banners-red: #E84545;

// Shadows
@palette-shadow-1: 0px 40px 70px 3px rgba(28, 60, 78, 0.05);
@palette-shadow-2: 0px 16px 58px 3px rgba(28, 60, 78, 0.07);

// Inputs
@palette-color-input-number-handler: #D9D9D9;

// Mixins
.colorSvg(@color) {
  & > :global(svg) {
    :global(*[fill]:not([fill="none"]):not([fill-rule="no"])) {
      fill: @color;
    }
    :global(*[stroke]:not([stroke="none"])) {
      stroke: @color;
    }
  }
}
