.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .logoNameWrapper {
    display: flex;
    align-items: center;

    .logoWrapper {
      width: 6rem;
      height: 6rem;

      display: flex;
      align-items: center;
      justify-content: center;

      .logo {
        max-width: 6rem;
        max-height: 6rem;
      }
    }

    .name {
      margin-left: 0.6rem;
    }
  }

  .globalInfosActionsWrapper {
    display: flex;
    align-items: center;

    .createdAt {
      margin-left: 1rem;
    }

    .trialEndDate {
      margin-left: 1rem;
    }

    .editCompany {
      margin-left: 2rem;
    }

    .recomputeTechnicalStats {
      margin-left: 2rem;
    }
  }
}
