@import '~@palette/styles/palette-theme.less';

.wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;

  .container {
    width: 100%;
    overflow: hidden;
    height: 100%;
  }

  .centerInContainer {
    margin: auto;
  }
}

.table {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  border-radius: @border-radius-base;
  position: relative;

  &.fitInContainer {
    height: 100%;
  }

  .body {
    flex: 1;

    &.hasFooters {
      flex: inherit;
    }
  }

  &.fromLeft {
    border-radius: initial;
    border-top-left-radius: @border-radius-base;
    border-bottom-left-radius: @border-radius-base;
  }

  &.fromRight {
    border-radius: initial;
    border-top-right-radius: @border-radius-base;
    border-bottom-right-radius: @border-radius-base;
  }

  &.horizontalScrollingOnLeft {
    border-top-left-radius: @border-radius-base;
    border-bottom-left-radius: @border-radius-base;
  }

  &.horizontalScrollingOnRight {
    border-top-right-radius: @border-radius-base;
    border-bottom-right-radius: @border-radius-base;
  }

  &.borderless {
    border-radius: initial;
  }
}
