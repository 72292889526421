@import '~@palette/styles/typographies.less';
@import '~@palette/styles/colors.less';

.wrapper {
  position: relative;

  border-radius: 1.8rem;
  border: 0.1rem solid @palette-color-border-normal;
  padding: 1.5rem 2rem;
  min-width: fit-content;
  display: flex;
  flex-flow: column nowrap;

  .infosWrapper {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;

    & + .infosWrapper {
      margin-top: 1rem;
    }

    .infosBlock {
      display: flex;
      align-items: center;

      .infosBlockItem {
        & + .infosBlockItem {
          margin-left: 1rem;
        }

        &.collectionNameWrapper {
          .bodyBase1Medium();
          color: @palette-color-primary-blue;
        }

        &.nbDocumentsWrapper {
          .bodyBase1Bold();
          color: @palette-color-primary-teal;
        }

        &.memorySizeWrapper {
          .bodyBase1Medium();
          color: @palette-color-neutrals-dark;
        }

        &.storageSizeWrapper {
          .bodyBase1Medium();
          color: @palette-color-neutrals-dark;
        }

        &.avgObjSizeWrapper {
          .bodyBase1Bold();
          color: @palette-color-primary-teal;
        }
      }
    }
  }
}
